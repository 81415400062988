/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 14:58:21
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-13 16:30:06
 */

// import { parseTime } from '@/plugins/lib/modules/date'
// import { remit, getType } from '@/utils/options'
import { formStatus, getType } from '@/utils/options';

export var columns = function columns(ctx) {return [
  {
    label: '项目编码',
    kname: 'id' },

  {
    label: '项目名称',
    kname: 'name' },

  {
    label: '项目分类',
    kname: 'firstCategoryName' },

  {
    label: '价格',
    kname: 'price' },

  {
    label: '销量',
    kname: 'salesVolume' },

  {
    label: '创建时间',
    kname: 'createAt' },

  {
    label: '状态',
    kname: 'status' },

  {
    label: '操作',
    kname: 'btns',
    width: 160 }];};



export var searchOption = [
{
  kname: 'name',
  label: '项目名称：',
  component: {
    name: 'el-input' } },


{
  kname: 'firstCategoryId',
  label: '项目分类：',
  component: {
    name: 'selector',
    props: {
      type: 'categorysType',
      clearable: true,
      cascader: true } } },



{
  kname: 'createAt',
  label: '创建时间：',
  component: {
    name: 'c-date-picker',
    props: {
      clearable: true,
      type: 'daterange',
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd' } } }];